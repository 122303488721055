







































import { Vue, Component, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import DataTable from '@/shared/resources/components/tables/data-table/DataTable.vue';
import ActionsButton from '@/shared/resources/components/tables/data-table/actions/ActionsButton.vue';
import DocumentModel from '@/app/modules/documents/models/DocumentModel';
import ORMCollection from '@/core/bridge/orm/ORMCollection';
import ApiORMQueryBuilderExtended from '@/shared/lib/api/query-builders/ApiORMQueryBuilderExtended';
import ApiSettings from '@/core/api/settings/ApiSettings';
import TableTemplateInterface from '@/shared/lib/interfaces/TableTemplateInterface';
import Button from '@/shared/resources/components/buttons/Button.vue';

@Component({
  components: {
    Button,
    ActionsButton,
    DataTable,
  },
})
export default class DocumentsMinTable extends Vue {
  /**
   * Props
   */
  @Prop() private query!: ApiORMQueryBuilderExtended;
  @Prop({ default: 5 }) private limit!: number;

  /**
   * Data
   */
  private template: TableTemplateInterface = {
    search: false,
    limitSelect: false,
  };

  private headers: DataTableHeader[] = [
    {
      value: 'number',
      text: 'Numer',
    },
    {
      value: 'totalsWithCurrency.net',
      text: 'Kwota netto',
    },
    // {
    //   value: 'datePayment',
    //   text: 'Termin płatności',
    // },
    {
      value: 'status',
      text: 'Status',
    },
  ];

  /**
   * Methods
   */
  private statusClassName(document: DocumentModel) {
    return {
      'normal--text': !document.isOutdated && !document.isPaid,
      'success--text text--darken-1': document.isPaid,
      'font-weight-bold': true,
    };
  }

  private itemClassNames(document: DocumentModel) {
    return {
      'error white--text': document.isOutdated,
    };
  }

  private goToDetails(itemId: number) {
    this.$router.push({ name: 'invoices.details', params: { id: itemId.toString() } });
  }

  private printDocument(document: DocumentModel) {
    window.open(document.printUrl(ApiSettings.rawToken), '_blank');
  }

  /**
   * Handlers
   */
  private onClickDetailsAction(document: DocumentModel) {
    this.goToDetails(document.id);
  }

  private onClickPrintAction(document: DocumentModel) {
    this.printDocument(document);
  }

  private onRowClick(row: any) {
    this.goToDetails(row.item.id);
  }

  private onFetch(documents: ORMCollection) {
    this.$emit('fetch', documents);
  }
}
